<template>
  <div class="columns">
    <div class="column is-12">
      <v-main>
        <v-container fluid>

          <div class="back-button text-right mb-3">
            <v-btn icon @click="goBack">
              <v-icon color="grey darken-2">mdi-chevron-down</v-icon>
            </v-btn>
          </div>

          <v-container v-if="orders_transporter && orders_transporter.length === 0">
            <v-row justify="center" align="center">
              <v-col cols="12" md="8">
                <div class="text-center">
                  <h2 class="title is-3 mb-2">Пока нет свободных заказов</h2>
                  <b-button @click="$router.push('/activity-feed')" type="is-success is-light">
                    Назад
                  </b-button>
                </div>
              </v-col>
            </v-row>
          </v-container>

          <v-container v-else>
            <v-row v-for="(item, index) in orders_transporter" :key="item.id">
<!--              @click="goToOrder(item.id)"-->
              <v-card :variant="variant" :class="'w-100 mb-3 no-gradient-block-' + (index % 4 + 1)">
                  <v-card-text class="pb-0">
                      <div class="pa-3 pb-0">
                          <v-row>
                            <v-col class="pa-0">
                              <h1 class="title is-6 ml-3">{{  dateText(item.time) }}</h1>
                            </v-col>
<!--                            <v-col class="pa-0 tags_col">-->
<!--                              <b-taglist>-->
<!--                                <b-tag rounded v-if="item.status === 'created'" type="is-primary">Создан</b-tag>-->
<!--                                <b-tag rounded v-else-if="item.status === 'agreed'" type="is-info">Подтвержден</b-tag>-->
<!--                                <b-tag rounded v-else-if="item.status === 'completed'" type="is-success">Доставлен</b-tag>-->
<!--                                <b-tag rounded v-else-if="item.status === 'in_progress'" type="is-warning">В пути</b-tag>-->
<!--                                <b-tag rounded v-else-if="item.status === 'cancelled'" type="is-danger">Отменен</b-tag>-->
<!--                              </b-taglist>-->
<!--                            </v-col>-->
                          </v-row>
                          <v-row class="pt-2">
                              <v-col cols="2" class="column is-3">
                                  <img src="/assets/road.svg">
                              </v-col>
                              <v-col cols="10" class="column is-9 order-route">
                                  <v-row>
                                      <h1 class="title is-5">{{  item.from_location }}</h1>
                                  </v-row>
                                  <v-row>
                                      <h1 class="title is-5 mt-2">{{  item.to_location }}</h1>
                                  </v-row>
                              </v-col>
                          </v-row>

                        <div @click="toggleCollapse(index)" class="pt-5" style="cursor: pointer;">
                          <span>Дополнительная информация</span>
                          <v-icon>{{ showDetails[index] ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
                        </div>

                        <!-- Блок с дополнительной информацией, который показывается/скрывается по клику на кнопку -->
                        <div v-if="showDetails[index]" class="pt-2">

<!--                          <h3 class="title is-6">Тип транспорта</h3>-->
<!--                          <p>{{ getType(1) }}</p>-->

                          <div v-if="item.car_id">
                            <h3 class="title is-6">Машина</h3>
                            <p>{{ wrapperCar(item) }}</p>
                          </div>

                          <h3 class="title is-6" v-if="item.comment !== ''">Комментарий</h3>
                          <p class="mb-0 text-pre-wrap">{{ item.comment }}</p>

                        </div>
                      </div>
                  </v-card-text>
                  <v-card-actions>
                    <b-button type="is-text" @click="goToOrder(item.id)">ОТВЕТИТЬ</b-button>
<!--                    <b-button type="is-text" @click="refusalRequest(item.id)">СКРЫТЬ</b-button>-->
                  </v-card-actions>
              </v-card>
            </v-row>
          </v-container>


        </v-container>
      </v-main>
    </div>
  </div>

</template>

<script>

export default {
  name: 'OrderListTransporter',
  data() {
    return {
      variant: 'outlined',
      showDetails: [],
      types: {
        0: {
          image: '/assets/scooter.svg',
          text: 'Мотоцикл'
        },
        1: {
          image: '/assets/car.svg',
          text: 'Автомобиль'
        },
        2: {
          image: '/assets/big-car.svg',
          text: 'Грузовик'
        },
        3: {
          image: '/assets/air-balloon.svg',
          text: 'Неформат'
        }
      },
    };
  },
  created() {
    this.$store.dispatch('GET_AVAILABLE_ORDERS_TRANSPORTER');
  },
  computed: {
    orders_transporter: {
      get() {
        return this.$store.getters.AVAILABLE_ORDERS_TRANSPORTER;
      }
    },
  },
  methods: {
    wrapperCar(transportation) {
      console.log('Машина: ', transportation);
      if (transportation && transportation.car.mark && transportation.car.model) {
        return transportation.car.mark + ' ' + transportation.car.model
      } else {
        return '';
      }
    },
    goToOrder(id) {
      this.$router.push({ path: `/orders/response/${id}` }).catch(()=>{});
    },
    toggleCollapse(index) {
      // Изменяем состояние сворачивания для соответствующей карточки
      this.$set(this.showDetails, index, !this.showDetails[index]);
    },
    goBack() {
      this.$router.push('/activity-feed');
    },
    getType(type) {
      return this.types[type]['text'];
    },
    dateText(date) {
      return this.$store.getters.TEXT_DATE(date);
    },
  },
};
</script>

<style scoped>
.title.is-6 {
  font-size: 1.4em !important;
  margin-bottom: 0 !important;
}

.title.is-6 {
  font-size: 1.3em !important;
}
</style>
